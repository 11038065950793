import * as React from 'react'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa'
import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex'
import { faCcDiscover } from '@fortawesome/free-brands-svg-icons/faCcDiscover'
import { Container } from './Container'
import AnimateHeight from 'react-animate-height'
import Link from 'next/link'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons'

declare const iFrameResize: any

type Props = {
  title: string
  description: string
}
export const FAQSection: React.FC<Props> = ({ title, description }) => {
  React.useEffect(() => {
    if (iFrameResize)
      iFrameResize({ checkOrigin: false, heightCalculationMethod: 'taggedElement' }, '#SP_cmFDSnceVq2r7aASVGuT')
  }, [])
  return (
    <section className="text-center bg-gray-50">
      {/*language=SCSS*/}
      <style jsx>{`
        .title {
          font-size: 40px;
          color: #000000;
          font-weight: 700;
        }

        .description {
          font-size: 18px;
          color: #7c7c7c;
          font-weight: 400;
        }

        @media (max-width: 768px) {
          .title {
            font-size: 24px;
            color: #000000;
            font-weight: 700;
          }
        }
      `}</style>
      <Container>
        <div>
          <h2 className="title max-w-xs mx-auto lg:max-w-full">{title}</h2>
          <p className="description">{description}</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FAQ question={`What does your quote include?`}>
            <p>
              At NuBrakes, transparency is at the core of our process. Your quote includes everything: parts, labor, and
              taxes.
            </p>
            <p>
              After providing a preliminary quote, your technician will perform a thorough in-person inspection to
              confirm the recommended repairs, ensuring no surprises.
            </p>
          </FAQ>
          <FAQ question={`How does the diagnosis work?`}>
            <p>
              We’ll provide a preliminary diagnosis over the phone or via text to guide the next steps. During your
              appointment, your technician will perform a thorough inspection and determine the final recommended
              repairs.
            </p>
            <p>
              They’ll walk you through their findings, explain everything clearly, and provide a detailed inspection
              report. You’ll receive the report before making any decisions, ensuring full transparency and control.
            </p>
            <p>
              Once you’ve reviewed the findings, discussed your options, and authorized the work, we’ll get started
              right away. With NuBrakes, you’re always in the driver’s seat, and we aim to have you back on the road in
              no time.
            </p>
          </FAQ>
          <FAQ question={`Who handles the repairs?`}>
            <p>
              Your vehicle will be serviced by NuBrakes-certified technicians with years of hands-on experience in brake
              repair. Each technician is a trusted member of our team, ensuring your vehicle is in expert hands.
            </p>
          </FAQ>
          <FAQ question={`What type of parts do you use?`}>
            <p>
              We typically match the brake pad type to your vehicle’s Original Equipment Manufacturer (OEM)
              specifications, whether ceramic or semi-metallic. We always use Platinum-grade parts or better to ensure
              optimal braking performance and reliability.
            </p>
            <p>
              If you have specific preferences or questions about the parts used, we’re happy to discuss bespoke options
              tailored to your needs. At NuBrakes, your safety and satisfaction are our top priorities.
            </p>
          </FAQ>
          <FAQ question={`Do you offer a warranty`}>
            <p>
              Absolutely! All repairs are backed by our <Link href={'/warranty'}>NuBrakes Guarantee</Link>, which
              includes a warranty of up to 2 years / 24,000 miles. You can drive with confidence knowing your brakes are
              built to last.
            </p>
          </FAQ>
          <FAQ question={`Do you do any other auto repairs?`}>
            <p>
              While our primary focus is providing expert brake repairs and brake service, we also offer other essential
              maintenance items, such as oil changes and battery replacements. Unlike traditional repair shops, we don’t
              push unnecessary upsells—just honest, straightforward care to keep your vehicle running smoothly.
            </p>
            <p>
              If you have other service needs, speak to your service advisor—they’ll be happy to help explore how we can
              assist you.
            </p>
          </FAQ>
          <FAQ question={`How do I pay for my repairs?`}>
            <div className="px-3">
              <p className="answer">
                We make payment easy and convenient—no credit card is required to schedule your appointment. Once your
                service is complete, you can pay on-site using any major credit or debit card. We also offer flexible
                financing options to make it even easier to get the repairs you need. We do not accept cash as a payment
                method.
              </p>
              <div>
                <FontAwesomeIcon icon={faCcVisa} className={`mx-1`} size={'2x'} />
                <FontAwesomeIcon icon={faCcMastercard} className={`mx-1`} size={'2x'} />
                <FontAwesomeIcon icon={faCcAmex} className={`mx-1`} size={'2x'} />
                <FontAwesomeIcon icon={faCcDiscover} className={`mx-1`} size={'2x'} />
              </div>
            </div>
          </FAQ>
        </div>
      </Container>
    </section>
  )
}

export const FAQ: React.FC<{ question: string; children?: any }> = ({ question, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const height = isOpen ? 'auto' : 0
  return (
    <div className={'flex flex-col flex-grow'}>
      <div className="text-left rounded-md overflow-hidden border border-gray-200">
        <div>
          <div className="px-4 py-3 h-100 bg-white" style={{ cursor: 'pointer' }} onClick={() => setIsOpen(!isOpen)}>
            <div className="flex items-center">
              <div className="flex pr-4">
                <FontAwesomeIcon icon={faPlus} className={`mx-1`} size={'1x'} />
              </div>
              <p className="question mb-0">
                <strong className="capitalize">{question}</strong>
              </p>
            </div>
          </div>
          <AnimateHeight duration={350} height={height}>
            <div className="py-4 bg-white">
              <div>
                <div className="answer px-3">{children}</div>
              </div>
            </div>
          </AnimateHeight>
        </div>
      </div>
    </div>
  )
}
